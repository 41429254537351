<template>
	<div>
		<!-- 内容 -->
		<div class="ordermanagebox">
			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<el-input v-model="valuename"
								:placeholder="valuefirst == 1 ? '请输入售后编号' : valuefirst == 2 ? '请输入订单编号' : valuefirst == 3 ? '请输入物流单号' : ''">
								<template slot="prepend">
									<el-select v-model="valuefirst" placeholder="请选择">
										<el-option v-for="(item, index) in valueList" :key="item.value" :label="item.label"
											:value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input :placeholder="shopnamesku == 1 ? '请输入商品名称' : '请输入商品sku'" v-model="serveshopname">
								<template slot="prepend">
									<el-select v-model="shopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in serveshopList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<el-input v-model="valuesecondname"
								:placeholder="valuesecond == 1 ? '请输入会员昵称' : valuesecond == 2 ? '请输入收货人姓名' : valuesecond == 3 ? '请输入收货人手机号' : ''">
								<template slot="prepend">
									<el-select v-model="valuesecond" placeholder="请选择">
										<el-option v-for="(item, index) in valuesecondlist" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">订单类型：</label>
							<el-select v-model="ordertype" filterable clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['order.type.list']" :key="item.value"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<div class="picker-date-picker">
							<el-select v-model="valuetime" placeholder="请选择">
								<el-option v-for="(item, index) in timeList" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
							<el-date-picker v-model="valuetimes" type="datetimerange" :picker-options="pickerOptions"
								start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期"
								:default-time="['00:00:00', '23:59:59']">
							</el-date-picker>
						</div>
						<div class="search-item">
							<label class="search-label">售后原因：</label>
							<el-select v-model="sericereason" filterable clearable placeholder="请选择">
								<el-option v-for="(item, index) in common_info['order.refund.reason.list']" :key="index"
									:label="item.reason" :value="item.reason"></el-option>
							</el-select>
						</div>
						<!-- <div class="search-item">
							<label class="search-label">售后方式：</label>
							<el-select v-model="servicestatus" clearable placeholder="请选择">
								<el-option v-for="(item, index) in servicestatuslist" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div> -->

						<!-- <div class="search-item">
								<label class="search-label">售后状态：</label>
								<el-select v-model="tabstatus" filterable clearable placeholder="请选择">
									<el-option v-for="(item, index) in orderattributelist" :key="item.value"
										:label="item.label" :value="item.value"></el-option>
								</el-select>
							</div> -->
						<template v-if="is_filter">
							<!-- <div class="search-item">
								<label class="search-label">发货状态：</label>
								<el-select v-model="orderstatus" clearable placeholder="请选择">
									<el-option v-for="(item, index) in orderstatuslist" :key="item.value"
										:label="item.label" :value="item.value"></el-option>
								</el-select>
							</div> -->
							<!-- <div class="search-item">
								<label class="search-label">退货物流：</label>
								<el-select v-model="shipping" clearable placeholder="请选择">
									<el-option v-for="(item, index) in shippinglist" :key="item.value"
										:label="item.label" :value="item.value"></el-option>
								</el-select>
							</div> -->
							<!-- <div class="search-item">
								<label class="search-label">退款状态：</label>
								<el-select v-model="orderarea" clearable placeholder="请选择">
									<el-option v-for="(item, index) in orderarealist" :key="item.value"
										:label="item.label" :value="item.value"></el-option>
								</el-select>
							</div> -->
						</template>
						<!-- <div class="orderfilter" @click="filtertab">
							<span>高级筛选</span>
							<i class="el-icon-arrow-down" v-if="!is_filter"></i>
							<i class="el-icon-arrow-up" v-else></i>
						</div> -->
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="serviceseach">搜索</el-button>
						<el-button @click="resetting">重置条件</el-button>
						<el-button @click="exportTable">导出报表</el-button>
					</div>
				</div>
			</el-card>

			<div class="ordermanageboxbut">
				<div class="ordermanagenav ordermanagenavadd">
					<div class="ordermanagenavleft d-flex">
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane label="全部"></el-tab-pane>
							<el-tab-pane label="待处理"></el-tab-pane>
							<el-tab-pane label="已处理"></el-tab-pane>
						</el-tabs>
					</div>
					<div class="ordermanagenavright d-flex">
						<div class="manageright d-flex">
							<span :class="[sortIndex == 1 ? 'active' : '']" @click="ordersorted(1)">正序 <i
									class="el-icon-top"></i></span>
							<span :class="[sortIndex == 2 ? 'active' : '']" @click="ordersorted(2)">倒序 <i
									class="el-icon-bottom"></i></span>
						</div>
					</div>
				</div>
				<div v-if="tableData && tableData.length">
					<div class="ordertabel">
						<div class="ordertabeltitle servicetabletitle clearfloat">
							<p>商品信息</p>
							<p>售后</p>
							<p>订单状态</p>
							<p>操作</p>
						</div>
						<div class="ordertabelcon">
							<div class="ordertabelist" v-for="(item, index) in tableData" :key="index">
								<div class="ordertabelistup clearfloat">
									<div class="ordertabelistleft">
										<p>
											<strong>订单来源：</strong>
											{{ item.company_category_title }}
										</p>
										<!-- <p><strong>订单编号：</strong>{{ item.order_refund_no }}</p> -->
										<p><strong>售后编号：</strong>{{ item.order_refund_no }}</p>
										<p><strong>售后申请时间：</strong>{{ item.created_at }}</p>
										<p><strong>员工账号：</strong>{{ item.user && item.user.username }}</p>
									</div>
									<div class="ordertabelistright clearfloat" v-if="hasPerm(['order.aftersale.show'])">
										<a href="javascript:void(0);" @click="loopservice(item.id)">查看详情</a>
									</div>
								</div>
								<div class="ordertabelistcon servicetabel d-flex">
									<div class="order_item d-flex  align-items-center flex-wrap">
										<div class="order_itemlist clearfloat" v-for="(it, int) in item.goods" :key="int">
											<div class="order_itemimg">
												<img :src="it.pic" alt="" :onerror="errorImg">
											</div>
											<div class="order_itemtext">
												<a href="javascript:void(0);">{{ it.goods_name }}</a>
												<div class="orderguige clearfloat">
													<p v-for="(ite, ind) in it.attr" :key="ind">
														<span>{{ ite.specs_key }}：</span>{{ ite.specs_value }}
													</p>
												</div>
												<div class="orderbutlast">
													<p><span>单价：</span>{{ it.price }}</p>
													<p><span>数量：</span>{{ it.num }}</p>
												</div>
											</div>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_itemoney">
											<p class="ormoney">{{ item.type == 1 ? "退货退款" : item.type == 2 ? "换货" :
												item.type ==
													3 ? "仅退款" : "" }}</p>
											<div class="viprebate">
												<div class="intergral">积分：<span>{{ item.account_price }}</span></div>
												<p v-if="Number(item.money_price) > 0">现金：<span>{{ item.money_price
												}}</span>
												</p>
												<!-- <span>{{ item.refund_price }}</span>元 -->
											</div>
											<p class="orderfare" v-if="item.reason">申请理由：{{ item.reason }}</p>
											<p class="orderfare" v-if="item.desc">申请说明：{{ item.desc }}</p>
											<div class="orservicelist">
												<span v-for="(its, inds) in item.pic_list" :key="inds">
													<el-image style="width: 100%; height: 100%" :src="its"
														:preview-src-list="item.pic_list" :onerror="errorImg">
													</el-image>
													<!-- <img :src="its" alt=""
														:onerror="errorImg"> -->
												</span>
											</div>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_itemstatus">
											<!-- <span class="putong">普</span> -->
											<!-- <span class="kami" style="display: none;">卡</span> -->
											<!-- <span class="xuni" style="display: none;">虚</span> -->
											<span class="servicetype">{{ item.status == 0 ? "待处理" : item.status == 1 ?
												"同意售后"
												: item.status == 2 ? "已同意换货" : item.status == 3 ? "已拒绝售后" : item.status == 4
													?
													"已完成" : '' }}</span>
										</div>
									</div>
									<div class="order_item d-flex align-items-center">
										<div class="order_operate">
											<div class="order_operatelast">
												<div v-if="item.status == 0">
													<template v-if="hasPerm(['order.aftersale.handle'])">
														<!-- <div v-if="item.type == 2">
															<a href="javascript:void(0);" @click="exchangeed(item)">同意换货</a>
															<a href="javascript:void(0);" @click="refuse(item)">拒绝换货</a>
														</div>
														<div v-if="item.type == 1">
															<a href="javascript:void(0);" @click="agreeth(item)">同意退货</a>
															<a href="javascript:void(0);" @click="refusethcon(item)">拒绝退货</a>
														</div>
														<div v-if="item.type == 3 && item.order_send_status == 0">
															<a href="javascript:void(0);" v-if="item.is_agree == 0 && hasPerm(['order.aftersale.refund'])" @click="reimburse(item.id, item.refund_price)">退款</a>
														</div> -->
														<div v-if="item.type == 0 || item.type == 4">
															<a href="javascript:void(0);" @click="dispose(item, 4)">同意</a>
															<a href="javascript:void(0);" @click="dispose(item, 3)">拒绝</a>
														</div>
													</template>
												</div>
												<div v-else>
													<!-- <div v-if="item.is_agree == 1">
														<a href="javascript:void(0);"
															v-if="item.refund_status == 1 && item.is_receipt == 0 && hasPerm(['order.aftersale.receipt'])"
															@click="aftereceipted(item.id)">确认收货</a>
														<a href="javascript:void(0);"
															v-if="item.type == 2 && item.is_receipt == 1 && item.is_return == 0 && hasPerm(['order.aftersale.send'])"
															@click="reship(item.id)">重新发货</a>
														<a href="javascript:void(0);"
															v-if="(item.type == 1 || item.type == 3) && item.is_receipt == 1 && item.refund_status == 1 && hasPerm(['order.aftersale.refund'])"
															@click="reimburse(item.id, item.refund_price)">退款</a>
													</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="ordertabelistbuttom">
									<div class="ordertabelistbuttoms">
										<strong>收货信息：</strong>
										<p>
											<span>{{ item.order?.receiver_name }}</span>
											<span>{{ item.order?.receiver_mobile }}</span>
											<span>{{ item.order?.address }}</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="orderlastbut clearfloat">
						<div class="orderlastbutright">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="total, sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
				<template v-else>
					<div class="zntj" v-if="datatype">
						<img class="img-default" src="../../assets/images/home-empty.png" alt="" />
						<p>暂无数据</p>
					</div>
				</template>
			</div>
		</div>
		<!-- 同意售后弹层 -->
		<el-dialog title="同意售后" class="rejectedtc" :visible.sync="rejectedtype">
			<div class="rejectedbox">
				<h5 class="clearfloat">
					<i class="el-icon-warning"></i>
					<!-- <span>确认同意售后？同意后由客户填写退货快递单号，确认收到货后退款金额返还给客户。</span> -->
					<span>确认是否同意售后？</span>
				</h5>
				<div class="servicelist">
					<ul>
						<li>
							<input type="text" v-model="refundItem.after_sale" placeholder="请输入售后原因">
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="rejectedtype = false">取 消</el-button>
				<el-button class="addressqr" @click="tytuihuo()">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 拒绝售后弹层 -->
		<el-dialog title="拒绝售后" class="refusetc" :visible.sync="refusethtype">
			<div class="refusebox">
				<el-input type="textarea" resize="none" v-model="refundItem.after_sale" maxlength="255" show-word-limit
					class="textarea-box" placeholder="拒绝理由(最多输入255字)"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="refusethtype = false">取 消</el-button>
				<el-button @click="refuseth()" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 重新发货 -->
		<el-dialog title="重新发货" class="rejectedtc" :visible.sync="reshiptype">
			<div class="rejectedbox">
				<div class="servicelist">
					<ul>
						<li>
							<p>快递公司：</p>
							<template>
								<el-select v-model="express" filterable clearable placeholder="请选择快递公司">
									<el-option v-for="(item, index) in common_info['express.delivery.list']" :key="index"
										:label="item.value" :value="item.value">
									</el-option>
								</el-select>
							</template>
						</li>
						<li>
							<p>快递单号：</p>
							<input type="text" v-model="expresstext" placeholder="请输入快递单号">
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="reshiptype = false">取 消</el-button>
				<el-button class="addressqr" @click="reshipfh()">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 导出报表 -->
		<diy-export-more ref="diyExportMore" dialogExportKeys="after.sale.export.field.list"
			dialogExportUrl="/aftersale/export" :dialogExportIsSearch="true"></diy-export-more>
	</div>
</template>

<script>
export default {
	inject: ["reload"],
	data() {
		return {
			datatype: false,
			errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
			id: '',
			valuefirst: '1',//售后编号
			valueList: [{
				label: '售后编号',
				value: '1',
			}, {
				label: '订单编号',
				value: '2',
			}, {
				label: '物流编号',
				value: '3',
			}],
			valuename: '',//售后编号输入框
			shopnamesku: '1',//商品名称或SKU 
			serveshopList: [{
				label: '商品名称',
				value: '1',
			},
				// {
				// 	label: '商品SKU',
				// 	value: '2',
				// }
			],
			serveshopname: '',//商品名称字段
			valuesecond: '1',//会员昵称 
			valuesecondlist: [{
				label: '会员昵称',
				value: '1',
			}, {
				label: '收货人姓名',
				value: '2',
			}, {
				label: '收货人手机号',
				value: '3',
			}],
			valuesecondname: '',//会员昵称字段
			valuetime: '1',//申请时间
			timeList: [{
				label: '申请时间',
				value: '1',
			}
				// , {
				// 	label: '按发货时间',
				// 	value: '2',
				// }, {
				// 	label: '按完成时间',
				// 	value: '3',
				// }
			],
			pickerOptions: {
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '7天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '30天',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			valuetimes: '',//日期选择
			ordertype: '',//订单类型
			shipping: '',//退货物流
			shippinglist: [{
				label: '未收货',
				value: '0',
			}, {
				label: '已收货',
				value: '1',
			}],
			servicestatus: '',//售后状态
			servicestatuslist: [{
				label: '退货退款',
				value: '1',
			}, {
				label: '换货',
				value: '2',
			}, {
				label: '仅退款',
				value: '3',
			}],
			is_filter: false, //是否展开高级搜索
			orderstatus: '',//发货状态
			orderstatuslist: [{
				label: '未发货',
				value: '0',
			}, {
				label: '已发货',
				value: '1',
			}],
			orderarea: '',//退款资金状态
			orderarealist: [{
				label: '无退款',
				value: '0',
			}, {
				label: '退款中',
				value: '1',
			}, {
				label: '退款成功',
				value: '2',
			}, {
				label: '退款失败',
				value: '3',
			}],
			sericereason: '',//售后原因
			tabstatus: '',//售后状态
			orderattributelist: [{
				label: '待处理',
				value: '0',
			}, {
				label: '已处理',
				value: '1',
			}],
			checked: false,//是否筛选栏锁定显示
			sortIndex: 1, //正序倒序的切换
			ordersort: '',//订单排序 
			orderchecked: false,//是否选中订单列表
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			tableData: [],//列表数据
			rejectedtype: false,//控制退货是否显示
			bartertype: false,//控制换货是否显示
			addresslist: [],//收货地址列表
			addresstype: '',//收货地址
			money: 0,//展示退款金额
			tkmoney: '',//退款金额
			addresstyped: '',//退款收货地址
			refusetype: false,//控制拒绝换货是否显示
			refusethtype: false,//控制拒绝退货是否显示
			textareaValue: '',//备注
			textareaValueth: '',//退货备注
			activeName: 0,
			ordersort: "id", //排序
			ordersortcon: 'desc', //正序倒序
			reshiptype: false,
			express: '',//快递公司
			expresstext: '',//快递公司单号
			refundItem: '',
			refundType: '',
			common_info: {}
		};
	},
	created() {
		let data = {
			keys: ["order.type.list", "express.delivery.list", "order.refund.reason.list"]
		}
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
	},
	mounted() {
		this.servicelistapi();
	},
	methods: {
		// 提取搜索数据
		extractSearch() {
			let data = {
				search: [],
				order: [{
					key: this.ordersort,
					value: this.ordersortcon
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			if (this.valuename) {
				let obj = {
					"key": this.valuefirst == 1 ? 'order_refund_no' : this.valuefirst == 2 ? 'order_no' : this.valuefirst == 3 ? 'return_express_no' : '',
					"value": this.valuename,
					"op": this.valuefirst == 1 ? 'search' : this.valuefirst == 2 ? 'equal' : this.valuefirst == 3 ? 'equal' : '',
				}
				data.search.push(obj);
			}
			if (this.serveshopname) {
				let obj = {
					"key": this.shopnamesku == 1 ? 'goods_name' : 'goods_sku',
					"value": this.serveshopname,
					"op": 'search',
				}
				data.search.push(obj);
			}
			if (this.valuesecondname) {
				let obj = {
					"key": this.valuesecond == 1 ? 'nickname' : this.valuesecond == 2 ? 'receiver_name' : this.valuesecond == 3 ? 'receiver_mobile' : '',
					"value": this.valuesecondname,
					"op": this.valuefirst == 1 ? 'search' : this.valuefirst == 2 ? 'search' : this.valuefirst == 3 ? 'equal' : '',
				}
				data.search.push(obj);
			}
			if (this.ordertype) { //订单类型
				let obj = { "key": 'type', "value": this.ordertype, "op": "equal" }
				data.search.push(obj);
			}
			if (this.valuetimes) { //时间筛选
				let obj = { "key": 'created_at', "value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])], "op": "between" }
				data.search.push(obj);
			}
			if (this.servicestatus) {
				let obj = { "key": 'type', "value": this.servicestatus, "op": "equal" }
				data.search.push(obj);
			}
			if (this.orderstatus) {
				let obj = { "key": 'order_send_status', "value": this.orderstatus, "op": "equal" }
				data.search.push(obj);
			}
			if (this.shipping) {
				let obj = { "key": 'is_receipt', "value": this.shipping, "op": "equal" }
				data.search.push(obj);
			}
			if (this.orderarea) {
				let obj = { "key": 'refund_status', "value": this.orderarea, "op": "equal" }
				data.search.push(obj);
			}
			if (this.tabstatus) {
				let obj = { "key": 'status', "value": this.tabstatus, "op": "equal" }
				data.search.push(obj);
			}
			if (this.sericereason) {
				let obj = { "key": 'desc', "value": this.sericereason, "op": "search" }
				data.search.push(obj);
			}

			return data
		},
		// 打开导出报表弹窗
		exportTable() {
			this.$refs.diyExportMore.exportMore = true
		},

		servicelistapi() {
			this.datatype = false;
			const data = this.extractSearch()
			// //console.log(data);
			// return false;
			this.$get(this.$apis.refund, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
					this.datatype = true;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// tab切换
		handleClick(tab) {
			this.currentPage = 1;
			if (tab.index == 0) {
				this.tabstatus = '';
			}
			if (tab.index == 1) {
				this.tabstatus = "0";
			}
			if (tab.index == 2) {
				this.tabstatus = tab.index;
			}
			this.servicelistapi();
		},
		// 搜索
		serviceseach() {
			this.currentPage = 1;
			this.servicelistapi();
		},
		// 重置
		resetting() {
			this.valuename = '';
			this.serveshopname = '';
			this.valuesecondname = '';
			this.ordertype = '';
			this.valuetimes = '';
			this.servicestatus = '';
			this.orderstatus = '';
			this.shipping = '';
			this.orderarea = '';
			this.tabstatus = '';
			this.sericereason = '';
			this.reload();
			this.servicelistapi();
		},
		// 排序
		ordersorted(index) {
			this.sortIndex = index;
			if (index == 1) {
				this.ordersortcon = 'desc'
			} else {
				this.ordersortcon = 'asc'
			}
			this.servicelistapi();
		},
		// 确认收货
		aftereceipted(id) {
			this.$alert('确认已收到用户退换货物', '确认收货', {
				confirmButtonText: '确定',
				callback: action => {
					this.aftereceiptedapi(id)
				}
			});

		},
		// 确认收货接口
		aftereceiptedapi(id) {
			this.$put(this.$apis.aftereceipt + id).then(res => {
				if (res.code == 200) {
					this.servicelistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 重新发货
		reship(id) {
			this.id = id;
			this.reshiptype = true;
		},
		// 重新发货接口
		reshipfh() {
			if (!this.express) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请选择物流公司',
				});
				return false;
			}
			if (!this.expresstext) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请填写物流单号',
				});
				return false;
			}
			let data = {
				express: this.express,
				express_no: this.expresstext
			};
			// //console.log(data);
			this.$put(this.$apis.aftereship + this.id, data).then(res => {
				if (res.code == 200) {
					this.servicelistapi();
					this.reshiptype = false;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 退款
		reimburse(id, moneys) {
			this.$confirm('确认通过后退款金额' + moneys + '元将直接返还给用户！', '退款', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.reimburseapi(id)
			}).catch(() => {

			});
		},
		// 退款接口
		reimburseapi(id) {
			this.$put(this.$apis.refundtk + id).then(res => {
				if (res.code == 200) {
					this.$message({
						type: "success",
						duration: 1500,
						message: "退款成功",
						onClose: () => {
							this.servicelistapi();
						},
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 同意换货
		exchangeed(item) {
			this.id = item.id;
			this.bartertype = true;
			this.obtainaddress();
			// //console.log(item.id)

		},
		//同意换货请求
		confirmsale() {
			if (!this.addresstype) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请选择收货地址',
				});
				return false;
			}
			let data = {
				handle: 2,
				address: this.addresstype
			};
			// //console.log(data)
			this.$put(this.$apis.exchange + this.id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.bartertype = false;
					this.servicelistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 拒绝换货
		refuse(item) {
			this.id = item.id;
			this.refusetype = true;
		},
		// 拒绝换货请求
		refuseexchange() {
			if (!this.textareaValue) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请填写拒绝理由',
				});
				return false;
			}
			let data = {
				handle: 3,
				refuse_desc: this.textareaValue
			};
			// //console.log(data)
			this.$put(this.$apis.exchange + this.id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.refusetype = false;
					this.servicelistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 同意退货
		agreeth(item, index) {
			this.id = item.id;
			this.money = item.refund_price;
			this.rejectedtype = true;
			this.obtainaddress();
		},
		// 同意售后请求
		tytuihuo() {
			if (!this.refundItem.after_sale) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请填写售后原因',
				});
				return false;
			}
			let data = {
				handle: this.refundType,
				refuse_desc: this.refundItem.after_sale,
			};

			this.$put(this.$apis.exchange + this.refundItem.id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.rejectedtype = false;
					this.servicelistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},

		// 获取售后地址
		obtainaddress() {
			let data = {
				page: 1,
				limit: 100,
				order: [{
					key: "id",
					value: "desc"
				}]
			};
			// //console.log(data)
			this.$get(this.$apis.seaddress, data).then(res => {
				if (res.code == 200) {
					this.addresslist = res.data.list;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		// 拒绝退货请求
		refuseth() {
			if (!this.refundItem.after_sale) {
				this.$message({
					type: 'warning',
					duration: 2000,
					message: '请填写拒绝理由',
				});
				return false;
			}
			let data = {
				handle: this.refundType,
				refuse_desc: this.refundItem.after_sale
			};
			// //console.log(data)
			// return false;
			this.$put(this.$apis.exchange + this.refundItem.id, data).then(res => {
				// //console.log(res)
				if (res.code == 200) {
					this.refusethtype = false;
					this.servicelistapi();
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		loopservice(id) {
			this.$router.push({
				path: '/order/order_servicedetails',
				query: {
					id: id
				}
			});
		},
		filtertab() {
			this.is_filter = !this.is_filter
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.servicelistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.servicelistapi();
		},
		// 同意售后
		dispose(item, type) {
			item.after_sale = '';
			this.refundItem = JSON.parse(JSON.stringify(item));
			this.refundType = type;
			if (type == 4) {
				this.rejectedtype = true;
			} else {
				this.refusethtype = true;
			}

		},
	}
};
</script>
<style scoped>
@import url("css/order.css");

.zntj {
	width: 100%;
	padding: 40px 0;
	box-sizing: border-box;
}

.zntj img {
	display: block;
	max-width: 180px;
	margin: 0 auto 10px;
}

.zntj p {
	font-size: 14px;
	color: #666;
	text-align: center;
}

::v-deep .orderfirstleft .el-input__inner {
	height: 38px;
	line-height: 38px;
}

::v-deep .ordertimeinput .el-range-editor.el-input__inner {
	height: 38px;
}

.dialog-footer .addressqr:hover {
	color: #fff !important;
}

.ordermanageboxbut {
	margin-top: 20px;
}

.orderlastbutright {
	display: table;
	float: none;
	margin: 0 auto;
}

.el-select-dropdown.el-popper {
	max-width: 460px;
}

.servicelist ul li .el-select {
	width: 100%;
}

.servicelist ul li /deep/ .el-select .el-input {
	width: 100%
}

.refusebox .el-textarea {
	width: 100% !important;
}
</style>